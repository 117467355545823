<script>
  import { mapGetters, mapActions } from 'vuex'
  import { items } from '@/data/navigation'
  // import store from '@/store'

  export default {
    components: {
    },

    data () {
      return {
        drawer: true,
        switch1: 'on',
        color: 'dark',
        colors: ['primary', 'blue', 'success', 'red', 'teal'],
        right: false,
        permanent: true,
        miniVariant: false,
        expandOnHover: false,
        background: false,
        items: items,
        opacity: 0.4,
      }
    },
    created () {
      const sidebarShow = localStorage.getItem('sidebarShow') != null
        ? JSON.parse(localStorage.getItem('sidebarShow'))
        : true

      // const windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
      //
      // // console.log(windowWidth)
      //
      // if (windowWidth > 800) {
      this.setVerticalSidebarMini(!sidebarShow)
      // }
    },
    computed: {
      ...mapGetters(['getThemeMode']),
      bg () {
        return this.background
          ? 'https://images.pexels.com/photos/1687678/pexels-photo-1687678.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260'
          : undefined
      },
      getMiniVariant () {
        return this.miniVariant
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
    },
    methods: {
      ...mapActions(['changeVerticalSidebarDrawer', 'changeVerticalSidebarMini', 'setVerticalSidebarMini']),
      toggleSidebar () {
        this.changeVerticalSidebarMini()
        // console.log()
        localStorage.setItem('sidebarShow', JSON.stringify(!this.$store.getters.getThemeMode.verticalSidebarMini))
        this.expandOnHover = !this.expandOnHover
        // console.log(this.expandOnHover)
      // this.$emit("update:mini-variant");
      // console.log("check");
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: item.title,
        }
      },
    },
  }
</script>

<template>
  <v-navigation-drawer
    v-model="getThemeMode.verticalSidebarDrawer"
    :color="
      $vuetify.theme.dark ? 'dark' : getThemeMode.verticalSidebarDrawerColor
    "
    :expand-on-hover.sync="getThemeMode.verticalSidebarMini"
    :mini-variant="getThemeMode.verticalSidebarMini"
    :src="bg"
    :dark="getThemeMode.verticalSidebarDrawerColor != 'white' ? true : false"
    app
    :disable-resize-watcher="false"
    :mobile-breakpoint="960"
    height="100%"
    class="shadow-sm g-sidebar"
    :floating="true"
    :right="$vuetify.rtl"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :style="{ opacity: opacity }"
        v-bind="props"
        class="test"
      />
    </template>
    <div>
      <v-list nav>
        <v-list-item class="px-0 g-logo">
          <v-list-item-avatar class="g-logo-img">
            <v-img
              src="@/assets/images/logo_white.png"
            />
          </v-list-item-avatar>

          <v-list-item-title class="text-18 text-uppercase text-default g-logo-title">
            <span>We</span>Port
          </v-list-item-title>
          <v-scroll-x-transition>
            <v-btn
              icon
              @click.stop="toggleSidebar"
            >
              <v-tooltip
                v-if="!getThemeMode.verticalSidebarMini"
                right
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    color
                    v-on="on"
                  >
                    mdi-circle-slice-8
                  </v-icon>
                </template>
                <span>Открепить</span>
              </v-tooltip>
              <v-tooltip
                v-if="getThemeMode.verticalSidebarMini"
                right
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    color
                    v-on="on"
                  >
                    mdi-circle-outline
                  </v-icon>
                </template>
                <span>Закрепить</span>
              </v-tooltip>
            </v-btn>
          </v-scroll-x-transition>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list class="py-0">
        <template v-for="(item, i) in computedItems">
          <div
            v-if="item.subheader"
            :key="item.subheader"
            :class="miniVariant && 'px-1'"
          >
            <v-subheader>
              {{ item.subheader }}
            </v-subheader>

            <v-divider />
          </div>

          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
            <!--  -->
          </base-item-group>

          <base-item
            v-else-if="!item.subheader && !item.children"
            :key="`item-${i}`"
            :item="item"
          />
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<style lang="scss">
.ps-container {
  position: inherit !important;
}
.app-admin-wrap-layout-1.sidebar-mini {
  .v-toolbar {
    left: 56px !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .v-main {
    padding: 75px 0px 12px 56px !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    padding-bottom: 0px !important;
  }
  &.sidebar-close {
    .v-toolbar {
      left: 0px !important;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .v-main {
      padding: 75px 0px 12px 0px !important;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      padding-bottom: 0px !important;
    }
  }
}
</style>
