export const items = [
  {
    title: 'Дмитрий Бужин',
    icon: 'mdi-account',
    group: 'cab',
    children: [
      {
        title: 'Мой профиль',
        icon: 'mdi-circle-medium',
        to: 'profile',
      },
      {
        title: 'Компании и группы',
        icon: 'mdi-circle-medium',
        to: 'groups',
      },
      {
        title: 'Мои публикации',
        icon: 'mdi-circle-medium',
        to: 'publications',
        disabled: true,
      },
      {
        title: 'Люди',
        icon: 'mdi-circle-medium',
        to: 'people',
        disabled: true,
      },
      {
        title: 'Фото/видео',
        icon: 'mdi-circle-medium',
        to: 'media',
        disabled: true,
      },
      {
        title: 'Email',
        icon: 'mdi-circle-medium',
        to: 'email',
        disabled: true,
      },
      {
        title: 'Чат',
        icon: 'mdi-circle-medium',
        to: 'chat',
        disabled: true,
      },
    ],
  },
  {
    title: 'CRM',
    icon: 'mdi-folder-account',
    group: '',
    disabled: true,
    children: [
      {
        title: '',
        icon: 'mdi-circle-medium',
        to: '',
      },
    ],
  },
  {
    title: 'Публикации',
    icon: 'mdi-newspaper-variant-outline',
    group: '',
    disabled: true,
    children: [
      {
        title: '',
        icon: 'mdi-circle-medium',
        to: '',
      },
    ],
  },
  {
    title: 'Компании и группы',
    icon: 'mdi-account-multiple',
    group: '',
    disabled: true,
    children: [
      {
        title: '',
        icon: 'mdi-circle-medium',
        to: '',
      },
    ],
  },
  {
    title: 'Маркетплейс',
    icon: 'mdi-shopping',
    group: '',
    disabled: true,
    children: [
      {
        title: '',
        icon: 'mdi-circle-medium',
        to: '',
      },
    ],
  },
  {
    title: 'Аукционы',
    icon: 'mdi-bank',
    group: '',
    disabled: true,
    children: [
      {
        title: '',
        icon: 'mdi-circle-medium',
        to: '',
      },
    ],
  },
  {
    title: 'Инвестиции',
    icon: 'mdi-hand-coin',
    group: '',
    disabled: true,
    children: [
      {
        title: '',
        icon: 'mdi-circle-medium',
        to: '',
      },
    ],
  },
  {
    title: 'ТВ',
    icon: 'mdi-television',
    group: '',
    disabled: true,
    children: [
      {
        title: '',
        icon: 'mdi-circle-medium',
        to: '',
      },
    ],
  },
  {
    title: 'База знаний',
    icon: 'mdi-head-snowflake',
    group: '',
    disabled: true,
    children: [
      {
        title: '',
        icon: 'mdi-circle-medium',
        to: '',
      },
    ],
  },

  //   {
  //     subheader: "Apps",
  //     icon: "mdi-apps-box",
  //     divider: true
  //   },
]
